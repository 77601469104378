/* @tailwind base;
/* @tailwind components; */
/* @tailwind utilities; */


.site-layout-content {
	min-height: 280px;
	padding: 24px;
	background: #fff;
  }
  #components-layout-demo-top .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
	float: right;
	margin: 16px 0 16px 24px;
  }

  .dynamic-delete-button {
	position: relative;
	top: 4px;
	margin: 0 8px;
	color: #999;
	font-size: 24px;
	cursor: pointer;
	transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
	color: #777;
  }
  .dynamic-delete-button[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
  }